@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    width: calc(100% - 38px);
    max-width: 1600px;
  }
  .flex0030 {
    flex: 0 0 30%;
  }
  .btn {
    @apply w-[40px] h-[40px] rounded-full flex items-center justify-center active:bg-dark_hover_1;
  }
  .btn_secondary {
    @apply w-[45px] h-[45px] rounded-full flex items-center justify-center bg-dark_bg_2;
  }
  .p16 {
    @apply px-[16px];
  }
  .textPrimary {
    font-size: 16px;
    line-height: 21px;
    @apply text-dark_text_1;
  }
  .textSecondary {
    font-size: 14px;
    line-height: 19px;
    @apply text-dark_text_2;
  }
  .input {
    @apply w-full dark:bg-dark_bg_2 p-1.5 dark:text-dark_text_1 rounded-lg flex-1 outline-none;
  }
  .convos {
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .overflow_scrollbar {
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .online {
    border: 3px solid #00a884;
  }
  .hview {
    height: calc(100vh - 290px);
    padding: 8px 0;
  }
  .fileThumbnail:hover .removeFileIcon {
    display: block;
  }
  .callbg {
    background-image: linear-gradient(black, black),
      url(https://res.cloudinary.com/dmhcnhtng/image/upload/v1677358270/Untitled-1_copy_rpx8yb.jpg);
    background-size: cover;
    background-blend-mode: saturation;
  }
  .largeVideoCall {
    @apply absolute top-0 bottom-0 left-0 right-0 z-20 object-cover h-full bg-red-400 cursor-pointer;
  }
  .smallVideoCall {
    @apply w-28 h-32 object-cover absolute bottom-4 right-2 z-30 bg-blue-500 cursor-pointer rounded-lg;
  }
  .moveVideoCall {
    @apply bottom-32 transition-all duration-100;
  }
  .moveActionsComp {
    @apply block;
  }
}

/*----Animations----*/
.rotateAnimation {
  animation: rotate 0.3s forwards;
}
@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
}

/*----Custom scrollbar----*/
/*--Width--*/
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
/*--Track--*/
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
/*--Thumb--*/
.scrollbar::-webkit-scrollbar-thumb {
  background: #444e54;
}
/*---Emoji picker*/
.epr-dark-theme {
  background: #202c33 !important;
  border-radius: 0 !important;
  border: none !important;
}
.epr-dark-theme {
  width: 100% !important;
}

.openEmojiAnimation {
  animation: openAn 0.1s forwards;
}
@keyframes openAn {
  0% {
    transform: translateY(60px);
  }
}
.epr-search-container input {
  background: #222e35 !important;
  border-radius: 0 !important;
  border: none !important;
  font-size: 13px;
}
.epr-preview {
  display: none !important;
}
.epr-emoji-category-label {
  background: transparent !important;
  font-size: 15px;
  font-weight: normal !important;
}
/*--- Emoji Scroollbar
  /* width */
.epr-body::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
.epr-body::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.epr-body::-webkit-scrollbar-thumb {
  background: #444e54 !important;
}
.epr-category-nav {
  padding: 10px 0 !important;
}
.epr-category-nav .epr-active::after {
  content: "";
  width: 140px;
  height: 4px;
  background: #00a884;
  position: absolute;
  bottom: -10px;
  left: -55px;
}
.epr-header {
  display: flex;
  flex-direction: column-reverse;
}
.openToolsAnimation {
  animation: openTools 0.2s forwards;
}
@keyframes openTools {
  0% {
    transform: translateY(100%);
  }
}
/* React select */
.css-obio5r-control:hover {
  border-color: transparent !important;
}
.css-obio5r-control {
  box-shadow: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-1p3m7a8-multiValue {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #025144;
  border-radius: 5px !important;
}
.css-1p3m7a8-multiValue span {
  color: #fff !important;
}
.css-1p3m7a8-multiValue span:hover {
  background: transparent !important;
}
.css-tj5bde-Svg {
  background: red;
  border-radius: 50%;
}
.css-12a83d4-MultiValueRemove:hover {
  background: transparent !important;
}
.css-12a83d4-MultiValueRemove:hover svg {
  fill: #fff !important;
}
.css-qbdosj-Input input {
  color: #fff !important;
}
.css-1nmdiq5-menu {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #025144;
  border-radius: 5px !important;
}
.css-1n6sfyn-MenuList {
  background: transparent !important;
}

.select-text {
  user-select: text; /* Ensure text can be selected */
}


/*--- MenuList Scroollbar
  /* width */
.css-1n6sfyn-MenuList::-webkit-scrollbar {
  width: 6px !important;
}
/* Track */
.css-1n6sfyn-MenuList::-webkit-scrollbar-track {
  background: transparent !important;
}
/* Handle */
.css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
  background: #444e54 !important;
}
.css-10wo9uf-option span {
  color: #fff !important;
}

.createGroupAnimation {
  animation: cga 0.5s;
}
@keyframes cga {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}